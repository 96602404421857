// |------------------------------------------------------
// |------------------------------------------------------
// | Error
// |------------------------------------------------------
// |------------------------------------------------------

.error
	.page
		padding: 0

	&__wrapper
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		height: calc(100% - 80px)
		background: $white
		border-radius: 5px
		padding: spacing()
		box-shadow: 0 0 10px rgba($grey, 0.05)
		transition: all 0.2s ease-out
		&--no-results
			height: calc(100% - 160px)

	&__page
		height: 100%
		background: $white url('./assets/images/background/error.jpg') 0 0 no-repeat
		background-size: cover
		border-radius: 0

	&__icon
		display: block
		text-align: center
		background: transparent
		svg
			width: 70px
			height: auto
			.symbol
				fill: $secondary
				fill: $white
			path
				fill: $primary
				// opacity: 0.3
				&.gray
					fill: $grey-dark
				&.light
					fill: $white


	&__name
		color: $primary
		font:
			size: 9.5rem
			family: $font-bold
		margin-bottom: $block-spacing * -1
		letter-spacing: -0.15em

		span:nth-child(2)
			color: $white
			text-shadow: 1px 0px 0px rgb(0 0 0 / 50%), -1px 0px 0px rgb(0 0 0 / 50%), 0px 1px 0px rgb(0 0 0 / 50%), 0px -1px 0px rgb(0 0 0 / 50%)

	&__message
		color: $body-color
		margin-bottom: $block-spacing * 1.5
		text-align: center
		line-height: 1.2
		strong
			// display: block
			color: inherit
