// |------------------------------------------------------
// |------------------------------------------------------
// | Navigation
// |------------------------------------------------------
// |------------------------------------------------------

.navigation
	margin: 0
	display: flex
	flex-direction: column
	@include touch
		width: 100%

	&--primary
		@include desktop
			margin-top: auto

	&--secondary
		margin-top: auto
		margin-bottom: 10px
		padding-top: spacing(2)
		@include touch
			margin-top: spacing()
			border-top: 1px solid rgba($grey-lighter, 0.5)

	&__link
		display: block
		padding: spacing(2)
		border-radius: 5px
		font-family: $font-regular
		transition: $transition-base
		svg
			width: 20px
		@include desktop
			opacity: 0.5
			// margin-bottom: spacing(3)
		@include touch
			padding: spacing(3) spacing()
			font-size: 20px
		.svg,
		.fill
			fill: $white
		.stroke
			stroke: $white
		&:hover
			opacity: 1
			// background: rgb($white, 0.4)
		&.countries
			svg
				width: 23px
		&.is-active,
		&.router-link-active
			opacity: 1
			background: $white-ter
			@include touch
				background: transparent
				color: $secondary
			.svg,
			.fill
				fill: $primary
			.stroke
				stroke: $primary
		&--logout
			color: $danger
			.svg
				opacity: 0.8

.icon-button
	display: none
	height: 24px
	width: 24px
	cursor: pointer
	@include touch
		display: block
