// |------------------------------------------------------
// |------------------------------------------------------
// | Sidebar
// |------------------------------------------------------
// |------------------------------------------------------

.sidebar
	display: flex
	flex-direction: column
	justify-content: space-around
	align-items: center
	max-width: 90%
	width: 330px
	height: 100vh
	position: fixed
	bottom: 0
	right: 0
	top: 0
	z-index: 9998
	background: $black-bis
	transition: right 0.6s ease-out

	&__flag
		display: block
		margin: 10px auto
		width: calc(100% - 20px)
		height: 8px
		border-radius: 5px
		background: linear-gradient(133deg, rgba(255, 255, 255, 1) 50%, rgba(40, 0, 255, 1) 50%)

	+ .page
		@include desktop
			width: calc(100% - #{$sidebar-width})
	@include desktop
		position: sticky
		right: auto
		width: $sidebar-width
	@include touch
		background: $white
		right: -120vw
		justify-content: flex-start
		&.opened
			right: 0
		.tooltip-content
			opacity: 0

	&__head
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%
		height: 65px
		padding: spacing()
		border-bottom: 1px solid rgba($grey-lighter, 0.5)
		.logo
			svg
				fill: $black
				max-width: 80px

	&__top
		// position: fixed
		// top: spacing(2)
		position: relative
		margin-top: spacing()
		img
			height: 60px
		.country
			display: block
			display: none
			position: absolute
			overflow: hidden
			right: 4px
			bottom: 5px
			img
				width: 20px
				height: auto
				border-radius: 4px

	&__bottom
		position: fixed
		bottom: spacing(2)
		overflow: hidden
		// padding: spacing(3)
		img
			display: block
			margin: auto
			width: 35px
			border-radius: 100%
			// border: 2px solid $white
			transition: $transition-base
			// box-shadow: 0 0 3px $grey-light

.environment
	flex-wrap: nowrap
	user-select: none
	position: absolute
	top: 0
	left: 0
	text-transform: uppercase
	pointer-events: none
	&:before
		content: ''
		width: 2px
		height: 100vh
		background: $danger
		position: absolute
		top: 0
		left: 0
		bottom: 0
	.tag
		font-size: 8px
		margin-left: 2px
		border-top-left-radius: 0
		border-bottom-left-radius: 0
